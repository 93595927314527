@font-face {
  font-family: 'Ploni';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Ploni Regular'), local('Ploni-Regular'),
       url('./fonts/ploni/ploni-regular-aaa.woff2') format('woff2'),
       url('./fonts/ploni/ploni-regular-aaa.woff') format('woff');
}

@font-face {
  font-family: 'Ploni';
  font-style: medium;
  font-weight: 500;
  font-display: swap;
  src: local('Ploni Medium'), local('Ploni-Medium'),
       url('./fonts/ploni/ploni-medium-aaa.woff2') format('woff2'),
       url('./fonts/ploni/ploni-medium-aaa.woff') format('woff');
}

@font-face {
  font-family: 'Ploni';
  font-style: bold;
  font-weight: 700;
  font-display: swap;
  src: local('Ploni Bold'), local('Ploni-Bold'),
       url('./fonts/ploni/ploni-bold-aaa.woff2') format('woff2'),
       url('./fonts/ploni/ploni-bold-aaa.woff') format('woff');
}

strong {
  font-weight: bold;
}

/*@font-face {
  font-family: 'Ploni';
  font-style: bold;
 
  font-display: swap;
  src: local('Ploni Bold'), local('Ploni-Bold'),
       url('./fonts/ploni/ploni-bold-aaa.woff2') format('woff2'),
       url('./fonts/ploni/ploni-bold-aaa.woff') format('woff');
}*/


html *
{
   font-family: Ploni !important;
}

body {
  margin: 0;
  background-color: #EAF6FA;
}
.loader-container{
  height: 100vh;
}
.loader {
  width: 50px;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 8px solid;
  border-color: #000 #0000;
  animation: l1 1s infinite;
  margin: auto;
  margin-top: 45vh;
}
@keyframes l1 {to{transform: rotate(.5turn)}}
.frame {
  /* background-color: #EAF6FA; */
  background-color: white;
  padding-bottom: 40px;
}

.header {
  background-color: white;
  height: 102px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 41px;
  padding-right: 41px;
}

.header .header-logo {
  height: 52px;
  width: 210px;
}

.header .header-icons {
  display: flex;
  align-items: center;
}

.header .notification-icon {
  height: 19px;
  width: 15px;
  margin-inline-end: 15px;
}

.header .user-icon {
  height: 32px;
  width: 32px;
}




.content {
  /* background-color: #EAF6FA; */
  /* background-color: #F2F2F25C; */
  background-color: rgba(242, 242, 242, 0.36);

  margin: 0px 51px;
  border-radius: 20px;
}

.content .main {
  min-height: calc( 100vh - 102px - 40px );
}

.content .main .main-top {
  padding: 46px 58px 20px;
}

.content .main .main-body {
  display: flex;
  justify-content: space-between;
  align-items: top;
  border-top: 1px solid rgba(216, 216, 216, 0.83);
  min-height: calc( 100vh - 102px - 40px - 102px );

  /* margin-left: 130px; */
  /* margin-right: 130px; */
}

.content .main .left-main {
  margin-inline-end: 200px;
  display: flex;
  align-items: center;
}

.content .main.main-create-user .left-main, .content .main.main-verify-phone .left-main, .content .main.main-identification-succeeded .left-main {
  margin-inline-end: 50px;
}

.content .main .content-logo {
  height: 421px;
  width: 476px;
  /* margin-right: 200px; */
  /* margin-left: 130px; */
}

.content .main.main-password-reset .content-logo, .content .main.main-register .content-logo, .content .main-two-step-verification .content-logo {
  /* margin-top: 100px; */
  margin-top: 41px;
  height: 705px;
  width: 388px;
}

.content .main.main-successfully-logged-in .content-logo {
  height: 627px;
  width: 601px;
}

.content .main.main-create-user .content-logo, .content .main.main-verify-phone .content-logo, .content .main.main-identification-succeeded .content-logo {
  width: 687px;
  height: 504px;
}

.content .main .right-main {
  /* display: grid; */
  /* margin-right: 130px; */
  margin-top: 46px;
  /* margin-right: 58px; */
  margin-inline-start: 58px;
  max-width: 391px;
  min-width: 391px;
  /* width: 504px; */
}

.content .main.main-password-reset .right-main, .content .main.main-create-user .right-main, .content .main.main-identification-succeeded .right-main,
.content .main-two-step-verification .right-main,  .content .main-successfully-logged-in .right-main {
  max-width: 473px;
}

.content .main.main-verification-code-type .right-main, .content .main.main-verify-phone .right-main {
  max-width: 504px;
}

.content .main.main-register .right-main {
  margin-top: 26px;
}

.content .main.main-register .right-main .input {
  margin-top: 20px;
}


/* .content .main.main-password-reset .right-main { 
  margin-top: -300px;
} */

/* .content .main.main-successfully-logged-in .right-main { 
  margin-top: -200px;
} */


.content .main h1 {
  font-size: 28px;
  font-weight: 500;
  margin: 0;
}


.content .main h2 {
  font-size: 20px;
  font-weight: 500;
  color: #6A7377;
  margin: 0;
  margin-bottom: 5px;
}

.content .main h2.teal {
  font-size: 18px;
  color: #308590;
}

.content .main h2.list-item {
  display: list-item;
  margin-inline-start: 30px;
}

.content .main h3 {
  font-size: 22px;
  font-weight: normal;
  color: #1A5270;
  margin: 0;
  margin-bottom: 5px;
}

.content .main .center-div {
  display: flex;
  justify-content: center;
}

.content .main .button, .content .main .input {
  width: 389px;
  height: 50px;
  margin-top: 30px;
  background-color: white;
  /* border-color: #176087; */
  border-radius: 4px;
  font-size: 18px;
  text-align: right;
  display: flex;
  align-items: center;

  /* box-shadow: 2px 2px 4px 2px #17608714; */

}


.content .main .button-center {
  text-align: center;
  justify-content: center;
}

.content .main .button-space-between {
  text-align: center;
  justify-content: space-between;
}

.content .main .button-blue {
  background-color: #176087;
  color: white;
}

.content .main .button-green {
  background-color: #3596A4;
  color: white;
}

.content .main .button-teal {
  background-color: #308590;
  color: white;
}

.content .main .button-darkteal {
  background-color: #266A73;;
  color: white;
}

.content .main .button-small {
  width: 174px;
}

.content .main .button-row {
  display: flex;
}

.content .main .blue-border {
  border: 1px solid #20678B;
  color: #308590;
}

.content .main .input input {
  font-size: 22px;
  width: 100%;
  margin: 10px;
  outline: none;
  border: none;
}

.content .main .contact-type {
  /* width: 354px; */
  height: 56px;
  display: flex;
  /* justify-content: space-between; */
  /* align-items: center; */
}

.content .main  .switch {
  display: flex;
  cursor: pointer;
  background-color: #D2E1E8;
  border: 1px solid #20678B;

  width: 42px;
  height: 17px;
  border-radius: 9px;  
}

.content .main .switch.switch-selected {
  justify-content: end;
}

.content .main .switch .switch-item {
  background-color: #20678B;
  border-radius: 8px;
  width: 15px;
  height: 15px;
  margin: 1px;
  /* padding: 0 10px; */
}




.content .main .input input::placeholder {
  color: #176087;
  font-size: 18px;
}

/* .content .main.main-reset-password .input input::placeholder, .content .main.main-restore-username .input input::placeholder, .content .main.main.main-register .input input::placeholder {
  color: black;
} */

.content .main .error, .content .main .error::placeholder {
  color: #F75C5E !important;
  width: 354px;
}


.content .main .input .input-icon { 
  height: 27px;
  margin-inline-start: 15px;
}


.content .main.main-reset-password .input .input-icon, .content .main.main-restore-username .input .input-icon { 
  height: 30px;
  margin-inline-start: 15px;
}

.content .main .button .button-icon {
  height: 21px;
  margin-inline-start: 15px;
  margin-inline-end: 20px;
}

.content .main .button .less-than-icon, .content .main .button .greater-than-icon {
  height: 15px;
}

[dir=ltr] .content .main .button .less-than-icon {
  transform: scaleX(-1);
}

/*.content .main  .greater-than-icon {
  height: 17px;
  margin-left: 5px;
}*/


.content .main .id-icon { 
  position: relative;
  z-index: 1;
}

.content .main .open-dropdown-icon-div { 
  position: absolute;
  height: 40px;
  width: 40px;
  inset-inline-end: 0px;
  /* left: 0px; */
  /* right: 0px; */
  z-index: 1;
  cursor: pointer;
}
.content .main .open-dropdown-icon { 
  height: 7px;
  width: 14px;
  margin-inline-start: 13px;
  margin-inline-end: 13px;
  margin-top: 16.5px;
}

.content .main .dropdown {
  position: absolute;
  top: 0px;
  left: -1px;
  right: 0px;
  /* padding-right: 12px; */
  padding-inline-start: 12px;
  /* font-size: 22px; */
  background-color: white;
  border-radius: 4px;
  /* box-shadow: 2px 2px 4px 2px #17608714; */
}

.content .main .dropdown .item {
  display: flex;
  justify-content:space-between;
  align-items: center;
  margin: 14px 0;
  margin-inline-end: 40px;
  /* min-width: 80px; */
  text-align: right;
  cursor: pointer;
}

.content .main .open-id-type-dropdown {
  z-index: 3;
}
.content .main .id-type-dropdown.dropdown {
  z-index: 2;
  top: -2px;
  /* right: -1.5px; */
  inset-inline-start: -1.5px;
}

.content .main .phone-number {
  display: flex;
}

.content .main .phone-code-input {
  width: 152px;
}

.content .main .phone-code {
  display: flex;
  justify-content:space-between;
  align-items: center;
  margin-inline-end: 40px;
  min-width: 80px;
  text-align: right;
}

.content .main .flag-icon {
  height: 14px;
  width: 21px;
}

.content .main .phone-code-dropdown.dropdown {
  top: -2px;
  /* margin-right: 40px; */
  /* padding-right: 20px; */
  padding-inline-start: 20px;
  /* border-radius: 22px 0 0 22px; */
  width: 243px;
  /* right: -134px; */
  inset-inline-start: -134px;
}

.content .main .phone-code-dropdown.dropdown .item {
  margin-inline-end: 0;
}


  


.pointer {
  cursor: pointer;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.ltr {
  direction: ltr;
}

.rtl {
  direction: rtl;
}

.ltr-span {
  direction: ltr;
  white-space: nowrap;
  display: inline-block;
}

.mt5 {
  margin-top: 5px !important;
}

.mt10 {
  margin-top: 10px !important;
}

.mt15 {
  margin-top: 15px !important;
}

.mt20 {
  margin-top: 20px !important;
}

.mt25 {
  margin-top: 25px !important;
}

.mt30 {
  margin-top: 30px !important;
}

.mt35 {
  margin-top: 35px !important;
}

.mt40 {
  margin-top: 40px !important;
}

.mt50 {
  margin-top: 50px !important;
}

.mt60 {
  margin-top: 60px !important;
}

.mt70 {
  margin-top: 70px !important;
}


.ml5 {
  margin-inline-end: 5px !important;
}

.ml10 {
  margin-inline-end: 10px !important;
}

.ml15 {
  margin-inline-end: 15px !important;
}

.ml20 {
  margin-inline-end: 20px !important;
}

.ml30 {
  margin-inline-end: 30px !important;
}

.ml40 {
  margin-inline-end: 40px !important;
}

.mr5 {
  margin-inline-start: 5px !important;
}

.mr10 {
  margin-inline-start: 10px !important;
}

.mr15 {
  margin-inline-start: 15px !important;
}

.mr20 {
  margin-inline-start: 20px !important;
}

.mr30 {
  margin-inline-start: 30px !important;
}

.mr40 {
  margin-inline-start: 40px !important;
}


@media screen and (max-width: 1024px) {

  .content .main .main-top {
    padding-right: unset;
    padding-left: unset;
    padding-inline-start: 1%;
  }
  
  .content .main .right-main {
    max-width: 100%;
    min-width: unset;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-inline-start: unset;
  }
  .content .main .main-body form, .content .main .main-body .right-main-inner {
    width: 95%;
  }
  .content .main .button, .content .main .input {
    width: 100%;
  }
  .content .main .phone-code-input {
    width: 152px;
  }
}

@media screen and (max-width: 850px) {
  .content .main .main-top {
    padding-right: unset;
    padding-left: unset;
    padding-inline-start: 2.5%;
  }
  /* .content .main .main-body {
    display: initial;
  } */
  /* .content .main .main-top {
    display: flex;
    justify-content: center;
  } */
  .content .main .left-main {
    /* justify-content: center; */
    display: none;
  }
}

@media screen and (max-width: 480px) {
  .content {
    margin: 0px 21px;
  }
}

@media screen and (max-width: 480px) {
  .header .header-logo {
    height: 26px;
    width: 105px;
  }
}

/* @media screen and (max-width: 400px) {
  .content .main .main-body form {
    width: 95%;
  }
} */

@media screen and (max-width: 360px) {
  .header .header-logo {
    display: none;
  }
}


@media screen and (max-width: 1200px) {
  .content .main .left-main {
    margin-inline-end: 100px;
  }
}

@media screen and (max-width: 1100px) {
  .content .main .left-main {
    margin-inline-end: 50px;
  }
}
